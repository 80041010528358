import React from 'react'
import AuthenticatedRoute from '../components/AuthenticatedRoute'
import ApplicationLayout from '../components/ApplicationLayout'
import CommandMenuPrompter from '../components/Home/CommandMenuPrompter'
import AppLayout from '../components/AppLayout'

type IndexProps = {}

const Index = (props: IndexProps) => {
  return (
    <AuthenticatedRoute>
      <AppLayout>
        <CommandMenuPrompter />
      </AppLayout>
    </AuthenticatedRoute>
  )
}

export default Index
