import { Flex, Kbd } from '@chakra-ui/react'
import { tw } from '@minupalaniappan/brise'
import React, { useContext } from 'react'
import { ContainerProps } from '../../types'
import { StateStore } from '../Store'

type Props = {}

const Wrapper = tw.div<ContainerProps>`
  rounded
  border
  border-gray-200
  w-full
  p-2
  bg-gray-100
  cursor-pointer
  flex
  flex-row
  justify-center
  text-gray-500
  hover:bg-gray-200
  transition-all
`

const PositionedSpan = tw.span<ContainerProps>`
  relative
  bottom-[1px]
  left-[10px]
`

const CommandMenuPrompter = (props: Props) => {
  const { dispatch } = useContext(StateStore)

  return (
    <Flex
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      height={'100%'}
    >
      <Wrapper
        {...{
          onClick: () => {
            dispatch({
              type: 'TOGGLE_MODAL',
              payload: {
                key: 'commandMenu',
              },
            })
          },
        }}
      >
        Open Command Menu{' '}
        <PositionedSpan>
          <Kbd>Command</Kbd> + <Kbd>K</Kbd>
        </PositionedSpan>
      </Wrapper>
    </Flex>
  )
}

export default CommandMenuPrompter
